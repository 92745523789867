import React from 'react'
import {graphCheckboxService} from '../../models/dataService'

class CheckboxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false
        };
        this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
    }

    toggleCheckboxChange(event) {

        this.setState({isChecked: event.target.checked}, () => {
            console.log("Toggle: ", this.state.isChecked)
            graphCheckboxService.sendMessage(event.target.id, this.state.isChecked)
        });
    }

    render() {
        const {id, name, label} = this.props;
        return (
            <div>
                <input type="checkbox" id={id} name={name} value={label} onChange={this.toggleCheckboxChange}/>
                <label htmlFor={label} label={label}>{label}</label>
            </div>
        );
    }
}

export default CheckboxComponent
