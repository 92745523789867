import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

function getJSONRepos(query) {
  return fetch('http://localhost:5000/data').then(response => response.json());
}
  
export default getJSONRepos;