import React from 'react'

class CheckboxGroupComponent extends React.Component {
	constructor(props){
		super(props);
	}

	render() {
        const {children, legend} = this.props;
		const createCheckbox = React.Children.map(children, (child) => {
            return (
                <li>
                    {React.cloneElement(child,
                    {
                        id: child.props.id,
                        name: child.props.name,
                        label: child.props.label
                    }
                    )}
                </li>
			);
		});
    
		return (
            <div className="checkbox">
                <fieldset className="usa-fieldset-inputs usa-sans">
                    <legend className="usa-sr-only">{legend}</legend>
                    <ul className="usa-unstyled-list">
                        {createCheckbox}
                    </ul>
                </fieldset>
            </div>
		);
	}
}

export default CheckboxGroupComponent