import  React, { useState } from "react";
import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { fredServices } from '../../services/fredServices';
import axios from 'axios';
import moment from 'react-moment';
import { graphCheckboxService } from '../../models/dataService'
import _ from "lodash";

class LineRechartComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    fetchData = async(id) => {
        const response = await axios.get('http://localhost:5000/data?id=' + id + '&units=' + (id == 'CPIAUCSL' ? 'pc1' : 'lin'))
        response.data.data.map(function(item, index) {
            item['date'] = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(item.timestamp * 1000);
        });
        if(this.state.data.length == 0) {
            this.setState({data: response.data.data});
        } else {
            if(id == 'DGS3MO') {
                const mergedResult = _.map(this.state.data, function(item){
                    return _.extend(item, _.find(response.data.data, { date: item.date }));
                });
                this.setState({data: mergedResult});
                console.log(this.state.data)
            } else if(id == 'DGS10') {
                const mergedResult = _.map(this.state.data, function(item){
                    return _.extend(item, _.find(response.data.data, { date: item.date }));
                });
                this.setState({data: mergedResult});
                console.log(this.state.data)
            } else if(id == 'CPIAUCSL') {
                const mergedResult = _.map(this.state.data, function(item){
                    return _.extend(item, _.find(response.data.data, { date: item.date }));
                });
                this.setState({data: mergedResult});
                console.log(this.state.data)
            }
        }
        console.log(this.state.data)
    }

    componentDidMount() {
        this.subscription = graphCheckboxService.getMessage().subscribe((data) => {
            this.fetchData(data.id)
        });
    }

    render() {

        return (
            <LineChart width={1000} height={500} data={this.state.data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" label="3 Month Treasury Rate" dataKey="DGS3MO" stroke="#800080" />
                <Line type="monotone" label="10 Year Treasury Rate" dataKey="DGS10" stroke="#FFA500" />
                <Line type="monotone" label="US Consumer Price Index YoY" dataKey="CPIAUCSL" stroke="#0095FF" />
            </LineChart>
        )
    };
}

export default LineRechartComponent;