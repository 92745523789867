import { Subject } from 'rxjs';

const subject = new Subject();

export const graphCheckboxService = {
    sendMessage: (key, value) => subject.next(
        { 
            id: key,
            flag: value 
        }),
    clearMessages: () => subject.next(),
    getMessage: () => subject.asObservable()
};